<div>
  <app-common-logo></app-common-logo>
  <div class="payment-container">
    <div *ngIf="is_pay_succeed()">
      <br />
      <h2 class="pay_success">支付成功</h2><br /><br />
      <span>订单号：{{order_id}}</span><br />
      <span>我们将尽快处理您的申请，谢谢！</span><br />
    </div>
    <div *ngIf="!is_pay_succeed()">
      <br />
      <h2 *ngIf="!is_pay_succeed()">未支付，请稍后刷新界面查看</h2><br /><br />
      <span>订单号：{{order_id}}</span><br /> </div>
  </div>
  <app-common-copyright></app-common-copyright>

</div>