<div>
  <div *ngFor="let current_value of current_values" [ngSwitch]="current_value.config.type" class="evaluate_item">
    <app-common-date *ngSwitchCase="'date'" [value]="current_value">
    </app-common-date>
    <app-common-input *ngSwitchCase="'input'" [value]="current_value">
    </app-common-input>
    <app-common-radio *ngSwitchCase="'radio'" [value]="current_value">
    </app-common-radio>
    <app-common-select *ngSwitchCase="'select'" [value]="current_value">
    </app-common-select>
    <app-common-slider *ngSwitchCase="'slider'" [value]="current_value">
    </app-common-slider>
    <app-common-toggle *ngSwitchCase="'toggle'" [value]="current_value">
    </app-common-toggle>
    <app-common-title *ngSwitchCase="'title'" [value]="current_value">
    </app-common-title>
    <app-common-loop *ngSwitchCase="'loop'" [value]="current_value">
    </app-common-loop>
    <app-common-textarea *ngSwitchCase="'textarea'" [value]="current_value">
    </app-common-textarea>
    <app-common-line *ngSwitchCase="'line'">
    </app-common-line>
  </div>
</div>