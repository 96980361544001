import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-common-title',
  templateUrl: './common-title.component.html',
  styleUrls: ['./common-title.component.scss']
})

export class CommonTitleComponent implements OnInit {
  @Input() value: any = {}

  constructor() { }

  ngOnInit() {
  }
}
