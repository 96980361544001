import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-common-loop',
  templateUrl: './common-loop.component.html',
  styleUrls: ['./common-loop.component.scss']
})

export class CommonLoopComponent implements OnInit {
  @Input() value: any = {}

  constructor() { }

  ngOnInit() {
  }

  on_click_remove() {
    --this.value["value"]
  }

  on_click_add() {
    ++this.value["value"]
  }
}
