import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import uncleji_evaluate_config from "../../../assets/json/uncleji_evaluate_config.json"
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AppHttpService, http_post_type } from 'src/app/app-http.service';
import { ClientLoginService } from '../client-login/client-login.service';

enum client_evaluate_page_type {
  client_home,
  client_evaluate,
  waiting,
}

@Component({
  selector: 'app-client-evaluate',
  templateUrl: './client-evaluate.component.html',
  styleUrls: ['./client-evaluate.component.scss']
})

export class ClientEvaluateComponent implements OnInit {
  page_type = client_evaluate_page_type.client_home
  values = {}
  client_home = {
    show_evaluate_button: false,
  }
  evaluate_progress = 0
  uncleji_evaluate_config = uncleji_evaluate_config

  constructor(
    private router: Router,
    private activated_route: ActivatedRoute,
    private http: AppHttpService,
    private login: ClientLoginService
  ) {
  }

  ngOnInit() {
    this.evaluate_progress = 0
    let self = this
    this.login.init({
      on_succeed_callback: function () {
        self.on_init_succeed()
      },
    })
  }

  on_init_succeed() {
    let self = this
    self.client_home.show_evaluate_button = true
    console.log("on_init_succeed")
  }

  on_click_evaluate() {
    this.page_type = client_evaluate_page_type.client_evaluate
  }

  is_show_client_home() {
    return this.page_type === client_evaluate_page_type.client_home
  }

  is_show_client_evaluate() {
    return this.page_type === client_evaluate_page_type.client_evaluate
  }

  get current_values() {
    let current_values = []
    if (this.evaluate_progress < this.uncleji_evaluate_config.length) {
      let configs = this.uncleji_evaluate_config[this.evaluate_progress]
      let index = 0
      for (let i = 0; i < configs.length;) {
        let config = configs[i]
        let key = config.name
        if (config.type === "loop") {
          key += "_0"
        } else {
          key += "_" + index
        }

        let value = this.values[key] || {
          key: key,
          config: config,
          value: config.property.default,
          default_value: config.property.default,
          disabled: false
        }

        if (config.type === "loop") {
          if (value.value === 0) {
            current_values.splice(current_values.length - config.property.sub_content_count, config.property.sub_content_count)
            ++i
          } else if (index + 1 === value.value) {
            ++i
            index = 0
          } else {
            i -= config.property.sub_content_count
            ++index
            continue
          }
        } else if (config.type === "toggle" && config.property.sub_content_count !== undefined) {
          if (value.value) {
            ++i
          } else {
            i += config.property.sub_content_count + 1
          }
        } else if (config.type === "textarea") {
          if (value.value === value.default_value) {
            value.value = ""
          }
          ++i
        } else {
          ++i
        }
        current_values.push(value)
        this.values[key] = value
      }
    }
    return current_values
  }

  on_click_pre_button() {
    --this.evaluate_progress
  }

  on_click_next_button() {
    // collect current page data

    if (++this.evaluate_progress < uncleji_evaluate_config.length) {
      // do nothing
    } else {
      // submit result and jump to evaluate result
      let evaluate_values = {}
      for (let key in this.values) {
        evaluate_values[key] = this.values[key].value
      }

      let post_values = {
        command: "evaluate",
        data: JSON.stringify(evaluate_values),
        open_id: this.login.open_id || "",
      }

      let self = this
      this.http.post({
        type: http_post_type.client,
        post_values: post_values,
        on_succeed_callback: function (data) {
          //self.router.navigate(['/client/evaluate_result/' + data["results"][0]["id"]]);
          //window.open(data["results"][0]["url"], "_blank")
          location.href = data["results"][0]["url"]
        },
      })

      this.page_type = client_evaluate_page_type.waiting
    }
  }

  is_show_pre_button() {
    return this.evaluate_progress !== 0 && this.evaluate_progress < this.uncleji_evaluate_config.length
  }

  is_show_next_button() {
    return this.evaluate_progress < this.uncleji_evaluate_config.length
  }

  is_show_waiting() {
    return this.page_type === client_evaluate_page_type.waiting
  }
}
