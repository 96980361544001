<div>
  <app-common-logo></app-common-logo>
  <div class="box">
    <p>我们为您提供专业的</p>
    <mat-list role="list">
      <mat-list-item role="listitem">
        <app-common-country [value]="hongkong" class="common_country"></app-common-country>
      </mat-list-item>
      <mat-list-item role="listitem">
        <app-common-country [value]="australia" class="common_country"></app-common-country>
      </mat-list-item>
      <mat-list-item role="listitem">
        <app-common-country [value]="canada_ee" class="common_country"></app-common-country>
      </mat-list-item>
      <mat-list-item role="listitem">
        <app-common-country [value]="canada_quebec" class="common_country"></app-common-country>
      </mat-list-item>
    </mat-list>
    <p>评估服务</p>
  </div>

  <button mat-raised-button color="primary" (click)="on_click_evaluate()"
    *ngIf="value.show_evaluate_button">开始评估</button>
  <app-common-copyright></app-common-copyright>

</div>