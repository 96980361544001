import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { CommonShopService } from '../../common/common-shop/common-shop.service';
import { AppHttpService, http_post_type } from 'src/app/app-http.service';
import { ClientLoginService } from '../client-login/client-login.service';

@Component({
  selector: 'app-client-pay-result',
  templateUrl: './client-pay-result.component.html',
  styleUrls: ['./client-pay-result.component.scss']
})

export class ClientPayResultComponent implements OnInit {
  value = {
    "orders": {
    }
  }

  constructor(
    private router: Router,
    private activated_route: ActivatedRoute,
    private http: AppHttpService,
    private common_shop_service: CommonShopService,
    private login: ClientLoginService
  ) { }

  ngOnInit() {
    let self = this
    this.login.init({
      on_succeed_callback: function () {
        self.on_init_succeed()
      },
    })
  }

  on_init_succeed() {
    let post_values = {
      command: "get_pay_result",
      id: this.id,
      open_id: this.login.open_id
    }

    let self = this
    this.http.post({
      type: http_post_type.client,
      post_values: post_values,
      on_succeed_callback: function (data) {
        self.value = data["results"][0]
      }
    })
  }

  is_pay_succeed() {
    if (this.value["orders"]) {
      for (let order_id in this.value["orders"]) {
        let order = this.value["orders"][order_id]
        if (order["paid"]) {
          return true
        }
      }
    }
    return false
  }

  get id() {
    return this.activated_route.snapshot.params["id"]
  }

  get configs() {
    return {
      id: this.id,
      configs: this.common_shop_service.evaluate_configs
    }
  }

  get open_id() {
    return this.login.open_id
  }

  get order_id() {
    let result = ""
    if (this.value["orders"]) {
      for (let order_id in this.value["orders"]) {
        result = order_id
        let order = this.value["orders"][order_id]
        if (order["paid"]) {
          return result
        }
      }
    }
    return result
  }

  get order_price() {
    let result = 0
    if (this.value["orders"]) {
      for (let order_id in this.value["orders"]) {
        let order = this.value["orders"][order_id]
        let product_id = order["product_id"]
        let config = this.common_shop_service.get_config_by_product_id(product_id)
        if (config !== undefined) {
          result = config.price
        }

        if (order["paid"]) {
          return result
        }
      }
    }
    return result
  }
}
