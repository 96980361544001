import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-common-input',
  templateUrl: './common-input.component.html',
  styleUrls: ['./common-input.component.scss']
})

export class CommonInputComponent implements OnInit {
  @Input() value: any = {}

  constructor() { }

  ngOnInit() {
  }
}
