import { Injectable, isDevMode } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppHttpService, http_post_type } from 'src/app/app-http.service';

enum weixin_open_id_status {
  succeed = 1,
  expire = 2,
  not_subscribe = 3,
  invalid = 4
}

@Injectable({
  providedIn: 'root'
})

export class ClientLoginService {
  open_id = undefined
  protected _init = false
  protected _init_failed = false
  protected _on_succeed_callback = null
  protected _on_failed_callback = null

  constructor(protected router: Router, protected activated_route: ActivatedRoute, protected http: AppHttpService) {

  }

  init(param) {
    this._on_succeed_callback = param.on_succeed_callback
    this._on_failed_callback = param.on_failed_callback

    console.log("init", this.is_from_weixin())
    if (this.is_from_weixin()) {
      if (this.open_id === undefined) {
        let post_values = {
          command: "get_weixin_openid",
          code: this.activated_route.snapshot.queryParams["code"]
        }

        let self = this
        this.http.post({
          type: http_post_type.client,
          post_values: post_values,
          on_succeed_callback: function (data) {
            console.log("init", "on_succeed_callback")
            let status = data["results"][0]["status"]
            if (status === weixin_open_id_status.succeed) {
              self.on_init_succeed(data["results"][0]["open_id"])
            } else if (status === weixin_open_id_status.not_subscribe) {
              if (param["ignore_subscribe"]) {
                self.on_init_succeed(data["results"][0]["open_id"])
              } else {
                self.on_init_failed()
              }
            } else if (status === weixin_open_id_status.invalid) {
              self.on_init_failed()
            } else if (status === weixin_open_id_status.expire) {
              self.wechat_redirect(window.location.origin + window.location.pathname)
            }
          },
          on_failed_callback: function (data) {
            console.log("init", "on_failed_callback")
            self.on_init_failed()
          }
        })
      } else {
        this.on_init_failed()
      }
    } else {
      this.wechat_redirect(window.location.href)
    }
  }

  wechat_redirect(url) {
    let redirect_url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid="
      + "wx38b152a77fdade97"
      + "&redirect_uri="
      + encodeURIComponent(url)
      + "&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect"

    if (isDevMode()) {
    }
    else {
      location.href = redirect_url
    }
  }

  on_init_succeed(open_id) {
    this.open_id = open_id
    this._init = true
    this._init_failed = false
    if (this._on_succeed_callback) {
      this._on_succeed_callback()
    }
  }

  on_init_failed() {
    this._init = false
    this._init_failed = true
    if (this._on_failed_callback) {
      this._on_failed_callback()
    } else {
      this.navigate_to_qrcode_page()
    }
  }

  is_from_weixin() {
    return undefined !== this.activated_route.snapshot.queryParams["code"]
  }

  is_show_qrcode() {
    if (this.is_from_weixin()) {
      if (this._init_failed) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  navigate_to_qrcode_page() {
    this.router.navigate(['/client/qrcode']);
  }
}
