import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { CommonShopService } from 'src/app/common/common-shop/common-shop.service';
import { CommonUtilsService } from 'src/app/common/common-utils/common-utils.service';

@Component({
  selector: 'app-admin-client-td',
  templateUrl: './admin-client-td.component.html',
  styleUrls: ['./admin-client-td.component.scss']
})

export class AdminClientTdComponent implements OnInit {
  @Input() value: any = {}

  constructor(
    private router: Router,
    private http: HttpClient,
    private common_shop_service: CommonShopService,
    private common_utils: CommonUtilsService
  ) { }

  ngOnInit() {
    console.log(this.value)
  }

  get id() {
    return this.value["id"] || undefined
  }

  get date() {
    return this.common_utils.get_time_string(this.value["timestamp"] || 0)
  }

  get name() {
    let name = this.value["evaluate"]["data"]["main_user_name_0"] || "[匿名]"
    if (this.value["evaluate"]["data"]["main_user_sex_0"] === 0) {
      name += " 先生"
    } else if (this.value["evaluate"]["data"]["main_user_sex_0"] === 1) {
      name += " 女士"
    }
    return name
  }

  get location() {
    return this.value["user"]["location"] || "[未知]"
  }

  get ip() {
    return this.value["user"]["ip"]
  }

  get type() {
    if (this.value["evaluate"] && this.value["orders"]) {
      for (let order_id in this.value["orders"]) {
        let order = this.value["orders"][order_id]
        if (order["paid"]) {
          let product_id = order["product_id"]
          let config = this.common_shop_service.get_config_by_product_id(product_id)
          if (config !== undefined) {
            return "[" + config.name + "][" + config.sub_name + "]"
          }
        }
      }
    }
    return "[免费]"
  }

  get status() {
    return this.value["evaluate"]["official_evaluate"] ? "[已评估]" : "[未评估]"
  }

  get open_id() {
    return this.value["user"]["open_id"] || "[未知]"
  }

  on_click_detail_button() {
    window.open(window.location.origin + '/admin/evaluate/' + this.id, '_blank');
  }

  on_click_report_button() {
    window.open(window.location.origin + '/admin/evaluate_report/' + this.id, '_blank');
  }
}
