import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { client_shop_type, CommonShopService } from '../../common/common-shop/common-shop.service';
import { AppHttpService } from 'src/app/app-http.service';
import { ClientLoginService } from '../client-login/client-login.service';
import uncleji_shop_config from "../../../assets/json/uncleji_shop_config.json"
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-client-big-sale',
  templateUrl: './client-big-sale.component.html',
  styleUrls: ['./client-big-sale.component.scss']
})

export class ClientBigSaleComponent implements OnInit {
  constructor(
    private router: Router,
    private activated_route: ActivatedRoute,
    private http: AppHttpService,
    private common_shop_service: CommonShopService,
    private login: ClientLoginService,
    private meta: Meta,
  ) { }

  ngOnInit() {
    let self = this
    this.login.init({
      on_succeed_callback: function () {
        self.on_init_succeed()
      },
      ignore_subscribe: true
    })

    this.meta.updateTag({ property: 'og:title', content: '吉叔工作室4人团签服务费缴费' });
    this.meta.updateTag({ property: 'og:description', content: '吉叔工作室4人团签服务费缴费' });
  }

  on_init_succeed() {

  }

  get configs() {
    return {
      id: 0,
      configs: this.common_shop_service.other_configs[client_shop_type.big_sale]
    }
  }

  get open_id() {
    return this.login.open_id
  }

  on_pay_succeed(e) {

  }
}
