<div>
  <app-common-logo></app-common-logo>
  <div>
    <mat-radio-group aria-label="Select an option" [(ngModel)]="filter">
      <mat-radio-button value="1">付费未评估</mat-radio-button>
      <mat-radio-button value="2">付费已评估</mat-radio-button>
      <mat-radio-button value="3">免费</mat-radio-button>
      <mat-radio-button value="4">全部</mat-radio-button>
    </mat-radio-group>
  </div>
  <div>
    <app-admin-client-td *ngFor="let client_data of client_datas" [value]="client_data">
    </app-admin-client-td>
  </div>

  <div>
    <button mat-raised-button color="primary" (click)="on_click_pre_page()" [disabled]="!is_show_pre_page()">上一页</button>

    <mat-form-field>
      <mat-select [(ngModel)]="current_page">
        <mat-option *ngFor="let item of total_page_range index as i" [value]="i">
          {{i}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-raised-button color="primary" (click)="on_click_next_page()" [disabled]="!is_show_next_page()">下一页</button>
  </div>
</div>