import { Component, OnInit, Input } from '@angular/core';
import { CommonShopService } from '../common-shop/common-shop.service';
import { CommonUtilsService } from '../common-utils/common-utils.service';

@Component({
  selector: 'app-common-billing',
  templateUrl: './common-billing.component.html',
  styleUrls: ['./common-billing.component.scss']
})

export class CommonBillingComponent implements OnInit {
  @Input() value: any = {}
  constructor(
    private common_shop_service: CommonShopService,
    private common_utils: CommonUtilsService
  ) { }

  ngOnInit() {
  }

  get date() {
    return this.common_utils.get_time_string(this.value["timestamp"])
  }

  get id() {
    return this.value["id"]
  }

  get name() {
    return this.common_shop_service.get_config_by_product_id(this.value["product_id"]).sub_name
  }

  get pay_status() {
    return this.value["paid"] ? "[已支付]" : "[未支付]"
  }
}
