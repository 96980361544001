import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AppHttpService, http_post_type } from 'src/app/app-http.service';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})

export class AdminHomeComponent implements OnInit {
  client_datas = []
  page = 0
  evaluate_length = 0
  filter = "1"

  constructor(private router: Router, private http: AppHttpService) { }

  ngOnInit() {
    this.query_evaluate_length()
  }

  query_evaluate(min, max) {
    let post_values = {
      min: min,
      max: max,
      command: "query_evaluate",
      filter: this.filter
    }

    let self = this
    this.http.post({
      type: http_post_type.admin,
      post_values: post_values,
      on_succeed_callback: function (data) {
        self.client_datas = []
        for (let i = 0; i < data["results"].length; ++i) {
          self.client_datas.push(JSON.parse(data["results"][i]))
        }
      }
    })
  }

  query_evaluate_length() {
    let post_values = {
      command: "query_evaluate_length",
      filter: this.filter
    }

    let self = this
    this.http.post({
      type: http_post_type.admin,
      post_values: post_values,
      on_succeed_callback: function (data) {
        let length = data["results"][0] || 0
        self.evaluate_length = length
        self.current_page = 0
      }
    })
  }

  on_click_pre_page() {
    if (0 < this.current_page) {
      --this.current_page
    }
  }

  on_click_next_page() {
    if (this.current_page < this.total_page - 1) {
      ++this.current_page
    }
  }

  is_show_pre_page() {
    return 0 < this.current_page
  }

  is_show_next_page() {
    return this.current_page < this.total_page - 1
  }

  get current_page() {
    return this.page
  }

  set current_page(value) {
    this.page = value
    this.query_evaluate(this.page * 10, this.page * 10 + 9)
  }

  get total_page() {
    return Math.ceil(this.evaluate_length / 10)
  }

  get total_page_range() {
    return Array(this.total_page)
  }
}
