import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import uncleji_evaluate_config from "../../../assets/json/uncleji_evaluate_config.json"
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AppHttpService, http_post_type } from 'src/app/app-http.service';

@Component({
  selector: 'app-admin-evaluate',
  templateUrl: './admin-evaluate.component.html',
  styleUrls: ['./admin-evaluate.component.scss']
})

export class AdminEvaluateComponent implements OnInit {
  values = {}
  server_values = {}
  uncleji_evaluate_config = uncleji_evaluate_config

  constructor(private activated_route: ActivatedRoute, private http: AppHttpService) {
  }

  ngOnInit() {
    this.query_evaluate()
  }

  query_evaluate() {
    let post_values = {
      command: "query_evaluate_by_id",
      id: this.activated_route.snapshot.params["id"]
    }

    let self = this
    this.http.post({
      type: http_post_type.admin,
      post_values: post_values,
      on_succeed_callback: function (data) {
        self.server_values = JSON.parse(data["results"][0])["evaluate"]["data"]
      }
    })
  }

  get current_values() {
    let current_values = []
    for (let i = 0; i < this.uncleji_evaluate_config.length; ++i) {
      let configs = this.uncleji_evaluate_config[i]
      let index = 0
      for (let i = 0; i < configs.length;) {
        let config = configs[i]
        let key = config.name
        if (config.type === "loop") {
          key += "_0"
        } else {
          key += "_" + index
        }

        let value = this.values[key] || {
          key: key,
          config: config,
          value: config.property.default,
          disabled: false
        }

        if (this.server_values[value.key] !== undefined) {
          value.value = this.server_values[value.key]
        }

        if (config.type === "loop") {
          if (value.value === 0) {
            current_values.splice(current_values.length - config.property.sub_content_count, config.property.sub_content_count)
            ++i
          } else if (index + 1 === value.value) {
            ++i
            index = 0
          } else {
            i -= config.property.sub_content_count
            ++index
            continue
          }
        } else if (config.type === "toggle" && config.property.sub_content_count !== undefined) {
          if (value.value) {
            ++i
          } else {
            i += config.property.sub_content_count + 1
          }
        } else {
          ++i
        }
        current_values.push(value)
        this.values[key] = value
      }
    }
    return current_values
  }
}
