<div>
  <div>
    <mat-slide-toggle [(ngModel)]="value.evaluate.score.hongkong.manual.enable">
      香港优才打分
    </mat-slide-toggle>
    <app-common-evaluate-hk *ngIf="value.evaluate.score.hongkong.manual.enable"
      [value]="value.evaluate.score.hongkong.manual">
    </app-common-evaluate-hk>
  </div>

  <mat-form-field class="report_field">
    <textarea class="report_textarea" matInput type="text" placeholder="评估内容"
      [(ngModel)]="value.evaluate.report.report"></textarea>
  </mat-form-field>

  <div>
    <button (click)="on_click_save()">
      保存/更新
    </button>
    <button (click)="on_click_push_to_client()">
      微信推送给客户
    </button>
  </div>
</div>