import { Component, OnInit, Input, Output } from '@angular/core';
import { CommonShopService } from '../../common/common-shop/common-shop.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppHttpService, http_post_type } from 'src/app/app-http.service';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-client-shop',
  templateUrl: './client-shop.component.html',
  styleUrls: ['./client-shop.component.scss']
})

export class ClientShopComponent implements OnInit {
  @Input() value: any = {}
  @Input() open_id = ""
  @Output() pay_emitter = new EventEmitter()
  selected_main_name = ""
  selected_sub_name = ""

  constructor(
    private router: Router,
    private activated_route: ActivatedRoute,
    private http: AppHttpService,
    private common_shop_service: CommonShopService,
  ) {
  }

  ngOnInit() {
    if (this.selected_main_name === "") {
      for (let i = 0; i < this.configs.length; ++i) {
        if (0 < this.configs[i].length) {
          this.selected_main_name = this.configs[i][0].name
          this.selected_sub_name = this.configs[i][0].sub_name
          break
        }
      }
    }
  }

  get configs() {
    return this.value["configs"] || {}
  }

  get main_names() {
    return this.configs
  }

  get sub_names() {
    for (let i = 0; i < this.configs.length; ++i) {
      if (0 < this.configs[i].length && this.configs[i][0].name === this.selected_main_name) {
        return this.configs[i]
      }
    }
    return []
  }

  get selected_item() {
    for (let i = 0; i < this.configs.length; ++i) {
      for (let j = 0; j < this.configs[i].length; ++j) {
        if (this.selected_main_name === this.configs[i][j].name && this.selected_sub_name === this.configs[i][j].sub_name) {
          return this.configs[i][j]
        }
      }
    }
    return undefined
  }

  get price() {
    let selected_item = this.selected_item
    if (selected_item !== undefined) {
      return selected_item.price
    } else {
      return 0
    }
  }

  on_main_name_changed(e) {
    for (let i = 0; i < this.configs.length; ++i) {
      if (0 < this.configs[i].length && this.configs[i][0].name === this.selected_main_name) {
        this.selected_sub_name = this.configs[i][0].sub_name
        break
      }
    }
    console.log(this.selected_main_name, this.selected_sub_name)
  }

  enable_pay() {
    return undefined !== this.selected_item
  }

  get id() {
    return this.value["id"] || 0
  }

  on_click_wechat_pay() {
    if (undefined !== this.selected_item) {
      let post_values = {
        command: "pay",
        id: this.id,
        open_id: this.open_id,
        product_id: this.selected_item.id,
        pay_type: "wechat_pay",
        return_url: window.location.href
      }

      let self = this
      this.http.post({
        type: http_post_type.client,
        post_values: post_values,
        on_succeed_callback: function (data) {
          function onBridgeReady() {
            window["WeixinJSBridge"].invoke(
              'getBrandWCPayRequest',
              data["results"][0],
              function (res) {
                self.pay_emitter.emit(res.err_msg === "get_brand_wcpay_request:ok")
                window.location.href = "https://weixin.uncleji.cn/client/pay_result/" + data["id"]
              }
            );
          }
          if (window["WeixinJSBridge"] === "undefined") {
            if (document.addEventListener) {
              document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
            } else if (document["attachEvent"] !== document["attachEvent"]) {
              document["attachEvent"]('WeixinJSBridgeReady', onBridgeReady);
              document["attachEvent"]('onWeixinJSBridgeReady', onBridgeReady);
            }
          } else {
            onBridgeReady();
          }
        },
        on_failed_callback: function () {
          self.pay_emitter.emit(false)
          alert("拉起支付控件失败，请联系薏米君")
        }
      })
    }
  }

  on_click_alipay() {
    if (undefined !== this.selected_item) {
      let post_values = {
        command: "pay",
        id: this.id,
        open_id: this.open_id,
        product_id: this.selected_item.id,
        pay_type: "alipay",
        return_url: window.location.href
      }

      let self = this
      this.http.post({
        type: http_post_type.client,
        post_values: post_values,
        on_succeed_callback: function (data) {
          let order_string = data["results"]
          let alipay_url = "https://openapi.alipay.com/gateway.do?" + order_string
          window.location.href = alipay_url;
        },
        on_failed_callback: function () {
          self.pay_emitter.emit(false)
        }
      })
    }
  }
}
