import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-wx-qrcode',
  templateUrl: './client-wx-qrcode.component.html',
  styleUrls: ['./client-wx-qrcode.component.scss']
})
export class ClientWxQrcodeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  is_mobile_browser() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    }
    else {
      return false;
    }
  }

  is_weixin_browser() {
    if (navigator.userAgent.match(/MicroMessenger/i)) {
      return true;
    }
    else {
      return false;
    }
  }
}
