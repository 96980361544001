import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-common-evaluate',
  templateUrl: './common-evaluate.component.html',
  styleUrls: ['./common-evaluate.component.scss']
})

export class CommonEvaluateComponent implements OnInit {
  @Input() value: any = {}

  constructor() { }

  ngOnInit() {
  }

  get current_values() {
    return this.value
  }
}
