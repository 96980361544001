import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppHttpService, http_post_type } from 'src/app/app-http.service';
import { ClientLoginService } from '../client-login/client-login.service';

@Component({
  selector: 'app-client-evaluate-report',
  templateUrl: './client-evaluate-report.component.html',
  styleUrls: ['./client-evaluate-report.component.scss']
})

export class ClientEvaluateReportComponent implements OnInit {
  disabled = true
  value: any = {
    evaluate: {
      data: {},
      paid: false,
      type: "free",
      official_evaluate: false,
      score: {
        hongkong: {
          machine: {
            score: 0
          },
          manual: {}
        },
        australia: {
          machine: {
            score: 0,
          },
          manual: {}
        },
        canada_ee: {
          machine: {
            score: 0,
          },
          manual: {}
        },
        canada_quebec: {
          machine: {
            score: 0,
          },
          manual: {}
        }
      },
      report: {}
    },
  }

  constructor(
    private router: Router,
    private activated_route: ActivatedRoute,
    private http: AppHttpService,
    private login: ClientLoginService
  ) { }

  ngOnInit() {
    let self = this
    this.login.init({
      on_succeed_callback: function () {
        self.on_init_succeed()
      },
    })
  }

  on_init_succeed() {
    let post_values = {
      command: "get_evaluate_report",
      id: this.activated_route.snapshot.params["id"]
    }

    let self = this
    this.http.post({
      type: http_post_type.client,
      post_values: post_values,
      on_succeed_callback: function (data) {
        console.log(data)
        self.value = data["results"][0]
      }
    })
  }
}
