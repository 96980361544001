import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-common-evaluate-machine',
  templateUrl: './common-evaluate-machine.component.html',
  styleUrls: ['./common-evaluate-machine.component.scss']
})

export class CommonEvaluateMachineComponent implements OnInit {
  @Input() value: any = {}

  constructor() { }

  ngOnInit() {
  }

  get name() {
    return this.value["name"] || ""
  }

  get score() {
    return this.value["score"] || 0
  }

  get min_score() {
    return this.value["min_score"] || 0
  }

  get avaliable() {
    return this.value["avaliable"] || false
  }
}
