<div>
  <div>
    <span>1 年齡（最高30分）</span>
    <div>
      <mat-form-field>
        <mat-select [(ngModel)]="value.age" [disabled]="disabled">
          <mat-option [value]="30">
            18-39 (30分)
          </mat-option>
          <mat-option [value]="20">
            40-44 (20分)
          </mat-option>
          <mat-option [value]="15">
            45-50 (15分)
          </mat-option>
          <mat-option [value]="0">
            51或以上 (0分)
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div>
    <span>2 學歷／專業資格（最高70分）</span>
    <div>
      <mat-form-field>
        <mat-select [(ngModel)]="value.degree" [disabled]="disabled">
          <mat-option [value]="40">
            博士學位／2個或以上碩士學位 (40分)
          </mat-option>
          <mat-option [value]="20">
            碩士學位／2個或以上學士學位 (20分)
          </mat-option>
          <mat-option [value]="10">
            學士學位／由國家或國際認可或著名的專業團體頒授，以證實持有人具有極高水平的專門知識或專業技能的專業資格 (10分)
          </mat-option>
          <mat-option [value]="0">
            其他學位 (0分)
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-checkbox [(ngModel)]="value.famous_school" [disabled]="disabled">
        <span>如學士或以上程度的學位是由國際認可的著名<br />院校頒授，可額外獲得分數 (30分)</span>
      </mat-checkbox>
    </div>
  </div>

  <div>
    <span>
      3 工作經驗（最高55分）
    </span>
    <div>
      <mat-form-field>
        <mat-select [(ngModel)]="value.work" [disabled]="disabled">
          <mat-option [value]="40">
            不少於10年相當於學位程度或專家水平的工作經驗，當中最少5年擔任高級職位 (40分)
          </mat-option>
          <mat-option [value]="30">
            不少於5年相當於學位程度或專家水平的工作經驗，當中最少2年擔任高級職位 (30分)
          </mat-option>
          <mat-option [value]="15">
            不少於5年相當於學位程度或專家水平的工作經驗 (15分)
          </mat-option>
          <mat-option [value]="5">
            不少於2年相當於學位程度或專家水平的工作經驗 (5分)
          </mat-option>
          <mat-option [value]="0">
            無工作經驗 (0分)
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-checkbox [(ngModel)]="value.international_work" [disabled]="disabled">
        <span>如擁有不少於2年相當於學位程度或專家水平的<br />國際工作經驗，可額外獲取分數 (15分)</span>
      </mat-checkbox>
    </div>
  </div>

  <div>
    <span>
      4人才清單（最高30分）
    </span>

    <div>
      <mat-form-field>
        <mat-select [(ngModel)]="value.qualified_profession" [disabled]="disabled">
          <mat-option [value]="1">
            廢物處理專家 (30分)
          </mat-option>
          <mat-option [value]="2">
            資產管理專才 (30分)
          </mat-option>
          <mat-option [value]="3">
            海運保險專才 (30分)
          </mat-option>
          <mat-option [value]="4">
            精算師 (30分)
          </mat-option>
          <mat-option [value]="5">
            金融科技專才 (30分)
          </mat-option>
          <mat-option [value]="6">
            數據科學家及網絡安全專家 (30分)
          </mat-option>
          <mat-option [value]="7">
            創新及科技專家 (30分)
          </mat-option>
          <mat-option [value]="8">
            造船師 (30分)
          </mat-option>
          <mat-option [value]="9">
            輪機工程師及船舶總管 (30分)
          </mat-option>
          <mat-option [value]="10">
            創意產業專才 (30分)
          </mat-option>
          <mat-option [value]="11">
            爭議解決專才及業務交易律師 (30分)
          </mat-option>
          <mat-option [value]="0">
            不属于任何一项 (0分)
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div>
    <span>
      5語文能力（最高20分）
    </span>

    <div>
      <mat-form-field>
        <mat-select [(ngModel)]="value.language" [disabled]="disabled">
          <mat-option [value]="20">
            良好中文及英文的書寫及口語能力（中文口語指普通話或粵語） (20分)
          </mat-option>
          <mat-option [value]="15">
            除了具備良好中文或英文的書寫及口語能力外（中文口語指普通話或粵語），也能流利應用不少於一種外國語言（包括書寫及口語能力） (15分)
          </mat-option>
          <mat-option [value]="10">
            良好中文或英文的書寫及口語能力（中文口語指普通話或粵語） (10分)
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div>
    <div>
      <span>
        6家庭背景（最高20分）
      </span>
    </div>

    <div>
      <mat-checkbox [(ngModel)]="value.hk_family" [disabled]="disabled">
        <span>6.1至少一名直系家庭成員（已婚配偶、父母、<br />兄弟姊妹、子女）是現居於香港的<br />香港永久性居民 (5分)</span>
      </mat-checkbox>
    </div>
    <div>
      <mat-checkbox [(ngModel)]="value.spouse_bachelor" [disabled]="disabled">
        <span>6.2隨行已婚配偶的學歷<br />相當於大學學位或以上的水平 (5分)</span>
      </mat-checkbox>
    </div>
    <div>
      <span>
        6.3每名隨行的18歲以下未婚及受養的子女得5分，最高可得10分(5/10分)
      </span>
      <div>
        <mat-form-field>
          <mat-select [(ngModel)]="value.children" [disabled]="disabled">
            <mat-option [value]="10">
              兩名及以上子女 (10分)
            </mat-option>
            <mat-option [value]="5">
              一名及以上子女 (5分)
            </mat-option>
            <mat-option [value]="0">
              無子女 (0分)
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div>
    <b>您的香港优才总分：{{total_score}}</b>
  </div>
</div>