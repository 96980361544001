import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { CommonShopService } from '../../common/common-shop/common-shop.service';
import { AppHttpService, http_post_type } from 'src/app/app-http.service';
import { ClientLoginService } from '../client-login/client-login.service';

@Component({
  selector: 'app-client-evaluate-result',
  templateUrl: './client-evaluate-result.component.html',
  styleUrls: ['./client-evaluate-result.component.scss']
})

export class ClientEvaluateResultComponent implements OnInit {
  value = {
    "evaluate": {
      "score": {
        "hongkong": {
          "machine": {
            "score": 0
          },
        }
      },
      "orders": {
      }
    }
  }

  constructor(
    private router: Router,
    private activated_route: ActivatedRoute,
    private http: AppHttpService,
    private common_shop_service: CommonShopService,
    private login: ClientLoginService
  ) { }

  ngOnInit() {
    let self = this
    this.login.init({
      on_succeed_callback: function () {
        self.on_init_succeed()
      },
    })
  }

  on_init_succeed() {
    let post_values = {
      command: "get_evaluate_result",
      id: this.id,
      open_id: this.login.open_id
    }

    let self = this
    this.http.post({
      type: http_post_type.client,
      post_values: post_values,
      on_succeed_callback: function (data) {
        self.value = data["results"][0]
      }
    })
  }

  is_pay_succeed() {
    if (this.value["evaluate"] && this.value["evaluate"]["orders"]) {
      for (let order_id in this.value["evaluate"]["orders"]) {
        let order = this.value["evaluate"]["orders"][order_id]
        if (order["paid"]) {
          return true
        }
      }
    }
    return false
  }

  get id() {
    return this.activated_route.snapshot.params["id"]
  }

  get configs() {
    return {
      id: this.id,
      configs: this.common_shop_service.evaluate_configs
    }
  }

  get evaluate_hongkong() {
    let score = this.value.evaluate.score.hongkong.machine.score || 0
    let min_score = 80
    return {
      name: "香港优才",
      score: score,
      min_score: min_score,
      avaliable: min_score <= score
    }
  }

  is_show_payment() {
    return !this.is_pay_succeed()
  }

  is_show_report_tips() {
    return this.is_pay_succeed()
  }

  is_report_finished() {
    return this.value["evaluate"] && this.value["evaluate"]["official_evaluate"]
  }

  on_pay_succeed(e) {
    this.login.wechat_redirect(window.location.origin + "/client/evaluate_result/" + this.id)
  }

  get open_id() {
    return this.login.open_id
  }

  on_click_report_button() {
    this.login.wechat_redirect(window.location.origin + "/client/evaluate_report/" + this.id)
  }

  get report_name() {
    if (this.value["evaluate"] && this.value["evaluate"]["orders"]) {
      for (let order_id in this.value["evaluate"]["orders"]) {
        let order = this.value["evaluate"]["orders"][order_id]
        if (order["paid"]) {
          let product_id = order["product_id"]
          let config = this.common_shop_service.get_config_by_product_id(product_id)
          if (config !== undefined) {
            return config.sub_name
          }
        }
        return JSON.stringify(order)
      }
    }
    return JSON.stringify(this.value["evaluate"])
  }

  is_evaluate() {
    if (this.value["evaluate"] && this.value["evaluate"]["order_type"]) {
      return 1 == this.value["evaluate"]["order_type"]
    }
    return true;
  }
}
