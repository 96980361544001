import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-common-toggle',
  templateUrl: './common-toggle.component.html',
  styleUrls: ['./common-toggle.component.scss']
})

export class CommonToggleComponent implements OnInit {
  @Input() value: any = {}

  constructor() { }

  ngOnInit() {
  }
}
