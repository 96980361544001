import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-common-copyright',
  templateUrl: './common-copyright.component.html',
  styleUrls: ['./common-copyright.component.scss']
})
export class CommonCopyrightComponent implements OnInit {

  constructor() { }
  currentYear: number = new Date().getFullYear();
  ngOnInit() {
  }
}
