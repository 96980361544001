<div>
  <app-common-logo></app-common-logo>
  <div>
    <img src="assets/img/gong_zhong_hao.jpg">
  </div>
  <div>
    <span>技术移民找吉叔！！！</span><br/>
    <span *ngIf="!is_weixin_browser() && !is_mobile_browser()">微信扫一扫</span>
    <span *ngIf="!is_weixin_browser() && is_mobile_browser()">微信中打开此链接，长按二维码</span>
    <span *ngIf="is_weixin_browser()">长按二维码</span>
    <span>关注吉叔工作室公众号</span>
  </div>
  <div>
    <span>免费评估打分，及时了解最新技术移民资讯</span>
  </div>
  <app-common-copyright></app-common-copyright>

</div>