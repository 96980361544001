<div class="box">
  <div>
    <span>时间：{{date}}</span>
    <span>订单号：{{id}}</span>
    <span>评估类型：{{type}}</span>
  </div>
  <div>
    <span>姓名：{{name}}</span>
    <span>地点：{{location}}</span>
    <span>ip：{{ip}}</span>
    <span>open_id: {{open_id}}</span>
  </div>
  <div>
    <span>评估状态:{{status}}</span>
  </div>
  <div>
    <button mat-raised-button color="primary" (click)="on_click_detail_button()">查看详情</button>
    <button mat-raised-button color="primary" (click)="on_click_report_button()">评估</button>
  </div>
</div>