<div>
  <app-common-logo></app-common-logo>
  <div class="box" *ngIf="value.evaluate.score.hongkong.manual.enable">
    <h3>香港优才分数</h3>
    <app-common-evaluate-hk [value]="value.evaluate.score.hongkong.manual" [disabled]="disabled">
    </app-common-evaluate-hk>
  </div>

  <div class="report_field">
    <h3>评估报告</h3>
    <span>
      {{value.evaluate.report.report}}
    </span>
  </div>
  <app-common-copyright></app-common-copyright>

</div>