import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-common-slider',
  templateUrl: './common-slider.component.html',
  styleUrls: ['./common-slider.component.scss']
})

export class CommonSliderComponent implements OnInit {
  @Input() value: any = {}

  constructor() { }

  ngOnInit() {
  }

  format_label(value: number | null): string {
    if (value == null) {
      return "";
    }
    else {
      return value + "";
    }
  }
}
