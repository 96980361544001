import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppHttpService, http_post_type } from 'src/app/app-http.service';
import { ClientLoginService } from '../client-login/client-login.service';

@Component({
  selector: 'app-client-evaluate-results',
  templateUrl: './client-evaluate-results.component.html',
  styleUrls: ['./client-evaluate-results.component.scss']
})

export class ClientEvaluateResultsComponent implements OnInit {
  value = []

  constructor(
    private router: Router,
    private activated_route: ActivatedRoute,
    private http: AppHttpService,
    private login: ClientLoginService
  ) { }

  ngOnInit() {
    let self = this
    this.login.init({
      on_succeed_callback: function () {
        self.on_init_succeed()
      },
    })
  }

  on_init_succeed() {
    let self = this
    let post_values = {
      command: "get_results",
      open_id: this.login.open_id || "",
    }

    this.http.post({
      type: http_post_type.client,
      post_values: post_values,
      on_succeed_callback: function (data) {
        self.value = data["results"]
        self.value.sort(function (a, b) {
          return b["timestamp"] - a["timestamp"]
        })
      },
    })
  }

  get results() {
    return this.value
  }
}
