import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClientHomeComponent } from './client/client-home/client-home.component';
import { ClientEvaluateComponent } from './client/client-evaluate/client-evaluate.component';
import { ClientEvaluateResultComponent } from './client/client-evaluate-result/client-evaluate-result.component';
import { CommonLogoComponent } from './common/common-logo/common-logo.component';
import { CommonTitleComponent } from './common/common-title/common-title.component';
import { CommonInputComponent } from './common/common-input/common-input.component';
import { CommonToggleComponent } from './common/common-toggle/common-toggle.component';
import { CommonSliderComponent } from './common/common-slider/common-slider.component';
import { CommonCheckboxComponent } from './common/common-checkbox/common-checkbox.component';
import { CommonDateComponent } from './common/common-date/common-date.component';
import { CommonSelectComponent } from './common/common-select/common-select.component';
import { CommonRadioComponent } from './common/common-radio/common-radio.component';
import { CommonLoopComponent } from './common/common-loop/common-loop.component';
import { AppMaterialModule } from './app-material.module';
import { FormsModule } from '@angular/forms';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';
import { AdminClientTdComponent } from './admin/admin-client-td/admin-client-td.component';
import { AdminEvaluateComponent } from './admin/admin-evaluate/admin-evaluate.component';
import { AdminEvaluateReportComponent } from './admin/admin-evaluate-report/admin-evaluate-report.component';
import { CommonEvaluateHkComponent } from './common/common-evaluate-hk/common-evaluate-hk.component';
import { ClientWxQrcodeComponent } from './client/client-wx-qrcode/client-wx-qrcode.component';
import { CommonEvaluateComponent } from './common/common-evaluate/common-evaluate.component';
import { CommonWaitingComponent } from './common/common-waiting/common-waiting.component';
import { CommonEvaluateMachineComponent } from './common/common-evaluate-machine/common-evaluate-machine.component';
import { ClientShopComponent } from './client/client-shop/client-shop.component';
import { ClientEvaluateReportComponent } from './client/client-evaluate-report/client-evaluate-report.component';
import { ClientEvaluateResultsComponent } from './client/client-evaluate-results/client-evaluate-results.component';
import { ClientBillingComponent } from './client/client-billing/client-billing.component';
import { ClientBillingsComponent } from './client/client-billings/client-billings.component';
import { CommonBillingComponent } from './common/common-billing/common-billing.component';
import { CommonEvaluateResultComponent } from './common/common-evaluate-result/common-evaluate-result.component';
import { ClientApplicationComponent } from './client/client-application/client-application.component';
import { CommonCountryComponent } from './common/common-country/common-country.component';
import { CommonLineComponent } from './common/common-line/common-line.component';
import { CommonTextareaComponent } from './common/common-textarea/common-textarea.component';
import { ClientCouponComponent } from './client/client-coupon/client-coupon.component';
import { ClientMacauComponent } from './client/client-macau/client-macau.component';
import { ClientCourseComponent } from './client/client-course/client-course.component';
import { ClientTestComponent } from './client/client-test/client-test.component';
import { ClientPrepayComponent } from './client/client-prepay/client-prepay.component';
import { ClientInterviewComponent } from './client/client-interview/client-interview.component';
import { ClientRegularCustomerComponent } from './client/client-regular-customer/client-regular-customer.component';
import { Client90offComponent } from './client/client-90off/client-90off.component';
import { ClientBigSaleComponent } from './client/client-big-sale/client-big-sale.component';
import { ClientHKComponent } from './client/client-hk/client-hk.component';
import { ClientGroupSaleComponent } from './client/client-group-sale/client-group-sale.component';
import { ClientPaypayPayTestComponent } from './client/client-pay-pay-pay-test/client-pay-pay-pay-test.component'
import { ClientPayResultComponent } from './client/client-pay-result/client-pay-result.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonCopyrightComponent } from './common/common-copyright/common-copyright.component';
import { CommonLogo2Component } from './common/common-logo2/common-logo2.component';

@NgModule({
  declarations: [
    AppComponent,
    ClientHomeComponent,
    CommonLogoComponent,
    CommonLogo2Component,
    CommonTitleComponent,
    CommonCopyrightComponent,
    CommonInputComponent,
    ClientEvaluateComponent,
    CommonToggleComponent,
    CommonSliderComponent,
    CommonCheckboxComponent,
    CommonDateComponent,
    CommonSelectComponent,
    CommonRadioComponent,
    CommonLoopComponent,
    ClientEvaluateResultComponent,
    AdminHomeComponent,
    AdminClientTdComponent,
    AdminEvaluateComponent,
    AdminEvaluateReportComponent,
    CommonEvaluateHkComponent,
    ClientWxQrcodeComponent,
    CommonEvaluateComponent,
    CommonWaitingComponent,
    CommonEvaluateMachineComponent,
    ClientShopComponent,
    ClientEvaluateReportComponent,
    ClientEvaluateResultsComponent,
    ClientBillingComponent,
    ClientBillingsComponent,
    CommonBillingComponent,
    CommonEvaluateResultComponent,
    ClientApplicationComponent,
    CommonCountryComponent,
    CommonLineComponent,
    CommonTextareaComponent,
    ClientCouponComponent,
    ClientMacauComponent,
    ClientRegularCustomerComponent,
    ClientGroupSaleComponent,
    Client90offComponent,
    ClientBigSaleComponent,
    ClientHKComponent,
    ClientInterviewComponent,
    ClientCourseComponent,
    ClientTestComponent,
    ClientPrepayComponent,
    ClientPaypayPayTestComponent,
    ClientPayResultComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AppMaterialModule,
    FormsModule,
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
