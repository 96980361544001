import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-logo2',
  templateUrl: './common-logo2.component.html',
  styleUrls: ['./common-logo2.component.scss']
})
export class CommonLogo2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
