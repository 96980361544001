<div class="payment-container" style="display: flex; justify-content: center; align-items: center;">
  <div class="pay_item">
    <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="selected_main_name" [value]="selected_main_name"
      class="mat-button-toggle-group-horizontal">
      <mat-button-toggle *ngFor="let main_name of main_names index as i" [value]="main_name[0].name"
        (change)="on_main_name_changed($event)">
        {{main_name[0].name}}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="pay_item">
    <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="selected_sub_name" [value]="selected_sub_name"
      class="mat-button-toggle-group-horizontal" >
      <mat-button-toggle class="sub_toggle" *ngFor="let sub_name of sub_names index as i" [value]="sub_name.sub_name">
        {{sub_name.sub_name}}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div style="margin:1em">
  </div>

  <div class="pay_item">
    <span class="pay_price">服务费：¥ {{price}}</span>
  </div>

  <div style="margin:1em">
  </div>

  <div class="pay_item">
    <button mat-raised-button class="wechat_pay_button" (click)="on_click_wechat_pay()"
      [disabled]="!enable_pay()">微信支付</button>
  </div>

  <div class="pay_item">
    <button mat-raised-button class="alipay_button" (click)="on_click_alipay()"
      [disabled]="!enable_pay()">支付宝支付</button>
  </div>
</div>

  <app-common-copyright></app-common-copyright>