<div>
  <app-common-logo></app-common-logo>
  <app-common-evaluate-machine [value]="evaluate_hongkong" *ngIf="is_evaluate()"></app-common-evaluate-machine>

  <div class="pay_tips" *ngIf="is_show_payment() && is_evaluate()">
    <span>以上评估结果为系统自动生成</span><br />
    <span>如需更准确更详细的评估报告</span><br />
    <span>我们提供付费评估服务</span><br />
    <span>给您最准确专业的申请建议</span>
  </div>

  <app-client-shop [value]="configs" [open_id]="open_id" *ngIf="is_show_payment()"
    (pay_emitter)="on_pay_succeed($event)">
  </app-client-shop>

  <div class="report_tips" *ngIf="is_show_report_tips() && is_evaluate()">
    <div *ngIf="!is_report_finished()">
      <span>您的<b>{{report_name}}</b>正在进行中</span><br />
      <span>评估报告稍后会通过公众号推送给您</span>
    </div>
    <div *ngIf="is_report_finished()">
      <span>您的<b>{{report_name}}</b>报告已完成</span><br />
      <button mat-raised-button color="primary" (click)="on_click_report_button()" class="report_button">查看报告</button>
    </div>
  </div>

  <div class="pay_tips" *ngIf="!is_evaluate()">
    <h2 color="green">支付成功</h2><br />
    <span>我们将尽快处理您的申请，谢谢！</span><br />
  </div>
  <app-common-copyright></app-common-copyright>

</div>
