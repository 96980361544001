import { Injectable } from '@angular/core';
import uncleji_shop_config from "../../../assets/json/uncleji_shop_config.json"

export enum client_shop_type {
  evaluate = 0,
  application = 1,
  open_course = 2,
  test = 3,
  coupon = 4,
  coupon2 = 5,
  prepay = 6,
  interview = 7,
  regular_customer = 8,
  _90off = 9,
  big_sale = 10,
  pay_pay_pay_test = 11,
  group_sale = 12,
  macau = 14,
  hk = 16,
}

@Injectable({
  providedIn: 'root'
})

export class CommonShopService {
  _evaluate_configs = []
  _evaluate_configs_dict = {}
  _application_configs = []
  _application_configs_dict = {}
  _other_configs = {}
  _other_configs_dict = {}

  constructor() {
    for (let i = 0; i < uncleji_shop_config[0].length; ++i) {
      let config = uncleji_shop_config[0][i]
      if (config && config.enable) {
        let shop_type = uncleji_shop_config[0][i].type
        switch (shop_type) {
          case client_shop_type.evaluate:
            if (this._evaluate_configs_dict[config.name] === undefined) {
              this._evaluate_configs_dict[config.name] = this.evaluate_configs.length
              this.evaluate_configs.push([])
            }
            this.evaluate_configs[this._evaluate_configs_dict[config.name]].push(config)
            break
          case client_shop_type.application:
            if (this._application_configs_dict[config.name] === undefined) {
              this._application_configs_dict[config.name] = this._application_configs.length
              this._application_configs.push([])
            }
            this._application_configs[this._application_configs_dict[config.name]].push(config)
            break
          default:
            this._other_configs_dict[shop_type] = this._other_configs_dict[shop_type] || []
            this._other_configs[shop_type] = this._other_configs_dict[shop_type] || []

            if (this._other_configs_dict[shop_type][config.name] === undefined) {
              this._other_configs_dict[shop_type][config.name] = this._other_configs[shop_type].length
              this._other_configs[shop_type].push([])
            }
            this._other_configs[shop_type][this._other_configs_dict[shop_type][config.name]].push(config)
            break
        }
      }
    }
  }

  get evaluate_configs() {
    return this._evaluate_configs
  }

  get_config_by_product_id(product_id) {
    for (let i = 0; i < uncleji_shop_config[0].length; ++i) {
      let config = uncleji_shop_config[0][i]
      if (config.id === product_id) {
        return config
      }
    }
    return undefined
  }

  get application_configs() {
    return this._application_configs
  }

  get other_configs(){
    return this._other_configs
  }
}
