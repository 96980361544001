import { Component, OnInit, Input, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';
import { common_country_type } from '../../common/common-country/common-country.component'


@Component({
  selector: 'app-client-home',
  templateUrl: './client-home.component.html',
  styleUrls: ['./client-home.component.scss']
})

export class ClientHomeComponent implements OnInit {
  @Input() value: any = {}
  @Output() evaluate_emitter = new EventEmitter()
  constructor(private router: Router, private activated_route: ActivatedRoute, private http: HttpClient) { }

  ngOnInit() {

  }

  on_click_evaluate() {
    this.evaluate_emitter.emit(null)
  }

  get hongkong() {
    return common_country_type.hongkong
  }

  get australia() {
    return common_country_type.australia
  }

  get canada_ee() {
    return common_country_type.canada_ee
  }

  get canada_quebec() {
    return common_country_type.canada_quebec
  }
}
