import { Component, OnInit, Input } from '@angular/core';

export enum common_country_type {
  unknown,
  hongkong,
  australia,
  canada_ee,
  canada_quebec,
}

@Component({
  selector: 'app-common-country',
  templateUrl: './common-country.component.html',
  styleUrls: ['./common-country.component.scss']
})

export class CommonCountryComponent implements OnInit {
  @Input() value: common_country_type = common_country_type.unknown

  constructor() { }

  ngOnInit() {
  }

  get name() {
    switch (this.value) {
      case common_country_type.hongkong:
        return "香港优才"
      case common_country_type.australia:
        return "澳洲技术"
      case common_country_type.canada_ee:
        return "加拿大联邦技术"
      case common_country_type.canada_quebec:
        return "加拿大魁省技术"
      default:
        return ""
    }
  }

  get src() {
    switch (this.value) {
      case common_country_type.hongkong:
        return "https://s.w.org/images/core/emoji/12.0.0-1/svg/1f1ed-1f1f0.svg"
      case common_country_type.australia:
        return "https://s.w.org/images/core/emoji/12.0.0-1/svg/1f1e6-1f1fa.svg"
      case common_country_type.canada_ee:
        return "https://s.w.org/images/core/emoji/12.0.0-1/svg/1f1e8-1f1e6.svg"
      case common_country_type.canada_quebec:
        return "https://s.w.org/images/core/emoji/12.0.0-1/svg/1f1e8-1f1e6.svg"
      default:
        return ""
    }
  }

  get alt() {
    switch (this.value) {
      case common_country_type.hongkong:
        return "🇭🇰"
      case common_country_type.australia:
        return "🇦🇺"
      case common_country_type.canada_ee:
        return "🇨🇦"
      case common_country_type.canada_quebec:
        return "🇨🇦"
      default:
        return ""
    }
  }
}
