<div>
  <app-client-home [value]="client_home" (evaluate_emitter)="on_click_evaluate()" *ngIf="is_show_client_home()">
  </app-client-home>
  <div *ngIf="is_show_client_evaluate()">
    <app-common-logo></app-common-logo>
    <app-common-evaluate [value]="current_values"></app-common-evaluate>
    <div class="page_button_group">
      <button mat-raised-button (click)="on_click_pre_button()" *ngIf="is_show_pre_button()"
        class="page_button">上一步</button>
      <button mat-raised-button color="primary" (click)="on_click_next_button()" *ngIf="is_show_next_button()"
        class="page_button">下一步</button>
    </div>
  </div>
  <app-common-waiting *ngIf="is_show_waiting()"></app-common-waiting>

</div>