import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AppHttpService, http_post_type } from 'src/app/app-http.service';

@Component({
  selector: 'app-admin-evaluate-report',
  templateUrl: './admin-evaluate-report.component.html',
  styleUrls: ['./admin-evaluate-report.component.scss']
})

export class AdminEvaluateReportComponent implements OnInit {
  value: any = {
    evaluate: {
      data: {},
      paid: false,
      type: "free",
      official_evaluate: false,
      score: {
        hongkong: {
          machine: {
            score: 0
          },
          manual: {}
        },
        australia: {
          machine: {
            score: 0,
          },
          manual: {}
        },
        canada_ee: {
          machine: {
            score: 0,
          },
          manual: {}
        },
        canada_quebec: {
          machine: {
            score: 0,
          },
          manual: {}
        }
      },
      report: {}
    },
  }

  constructor(private activated_route: ActivatedRoute, private http: AppHttpService) { }

  ngOnInit() {
    let post_values = {
      command: "query_evaluate_by_id",
      id: this.activated_route.snapshot.params["id"]
    }

    let self = this
    this.http.post({
      type: http_post_type.admin,
      post_values: post_values,
      on_succeed_callback: function (data) {
        self.value = JSON.parse(data["results"][0])
      }
    })
  }

  on_click_save() {
    this.save_and_push(false)
  }

  on_click_push_to_client() {
    this.push_to_client()
  }

  is_enable_evaluate_hk() {
    return true === this.value.evaluate.score.hongkong.manual.enable
  }

  is_enable_report() {
    return undefined !== this.value.evaluate["report"]["report"]
  }

  push_to_client() {
    this.save_and_push(true)
  }

  save_and_push(is_push_to_client) {
    let self = this
    let post_values = {
      command: "save_and_push_official_evaluate",
      id: this.activated_route.snapshot.params["id"],
      evaluate: {
        score: this.value.evaluate.score,
        report: this.value.evaluate.report,
      },
      user: this.value.user,
      push_to_client: is_push_to_client
    }

    this.http.post({
      type: http_post_type.admin,
      post_values: post_values,
      on_succeed_callback: function (data) {
      }
    })
  }
}
