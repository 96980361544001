import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-common-textarea',
  templateUrl: './common-textarea.component.html',
  styleUrls: ['./common-textarea.component.scss']
})

export class CommonTextareaComponent implements OnInit {
  @Input() value: any = {}

  constructor() { }

  ngOnInit() {
  }

}
