import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

export enum http_post_type {
  client,
  admin,
}

@Injectable({
  providedIn: 'root'
})

export class AppHttpService {
  http_options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(private router: Router, private activated_route: ActivatedRoute, private http: HttpClient) {

  }

  post(param) {
    let url = ""
    switch (param.type) {
      case http_post_type.admin:
        url = "/admin_post"
        break
      case http_post_type.client:
        url = "/client_post"
        break
    }
    console.log("post", window.location.origin + url)
    this.http.post(window.location.origin + url, param.post_values, this.http_options).subscribe(
      data => {
        console.log("POST Request is successful ", data);
        if (data["results"] && 0 < data["results"].length) {
          if (param.on_succeed_callback) {
            param.on_succeed_callback(data)
          }
        } else {
          if (param.on_failed_callback) {
            param.on_failed_callback(data)
          }
        }
      },
      error => {
        console.log("Error", error);
        if (param.on_failed_callback) {
          param.on_failed_callback(error)
        }
      }
    );
  }
}
