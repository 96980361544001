import { Component, OnInit, Input } from '@angular/core';
import { CommonUtilsService } from '../common-utils/common-utils.service';
import { CommonShopService } from '../common-shop/common-shop.service';

@Component({
  selector: 'app-common-evaluate-result',
  templateUrl: './common-evaluate-result.component.html',
  styleUrls: ['./common-evaluate-result.component.scss']
})

export class CommonEvaluateResultComponent implements OnInit {
  @Input() value = {}

  constructor(
    private common_utils: CommonUtilsService,
    private common_shop_service: CommonShopService
  ) { }

  ngOnInit() {
  }

  get date() {
    return this.common_utils.get_time_string(this.value["timestamp"])
  }

  get name() {
    let item = this.common_shop_service.get_config_by_product_id(this.value["product_id"])
    if (item !== undefined) {
      return item.sub_name
    } else {
      return "[免费评估]"
    }
  }

  get paid() {
    return this.value["paid"] ? "[已支付]" : "[未支付]"
  }

  is_report_avaliable() {
    let item = this.common_shop_service.get_config_by_product_id(this.value["product_id"])
    if (item !== undefined) {
      return this.value["official_evaluate"]
    } else {
      return true
    }
  }

  on_click_report_button() {
    window.location.href = this.value["url"]
  }
}
