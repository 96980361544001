import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class CommonUtilsService {

  constructor() { }

  format_int(number) {
    return this.prefix_integer(number, 2)
  }

  prefix_integer(num, length) {
    return (Array(length).join('0') + num).slice(-length);
  }

  get_time_string(timestamp) {
    var d = new Date(timestamp * 1000);
    var date = (d.getFullYear()) + "-" +
      this.format_int(d.getMonth() + 1) + "-" +
      this.format_int(d.getDate()) + " " +
      this.format_int(d.getHours()) + ":" +
      this.format_int(d.getMinutes()) + ":" +
      this.format_int(d.getSeconds());
    return date;
  }
}
