import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-common-evaluate-hk',
  templateUrl: './common-evaluate-hk.component.html',
  styleUrls: ['./common-evaluate-hk.component.scss']
})
export class CommonEvaluateHkComponent implements OnInit {
  @Input() disabled = false
  @Input() value: any = {
    age: 0,
    degree: 0,
    work: 0,
    language: 0,
    children: 0,
    famous_school: false,
    international_work: false,
    qualified_profession: 0,
    hk_family: false,
    spouse_bachelor: false,
  }

  // @Input() set value(value) {
  //   for (var key in this._value) {
  //     if (value[key] !== undefined) {
  //       this._value[key] = value[key]
  //     }
  //   }
  // }

  // get value() {
  //   return this._value
  // }

  constructor() { }

  ngOnInit() {
  }

  get total_score() {
    let score = 0

    if (this.value.age) {
      score += this.value.age
    }

    if (this.value.degree) {
      score += this.value.degree
    }

    if (this.value.famous_school) {
      score += 30
    }

    if (this.value.work) {
      score += this.value.work
    }

    if (this.value.international_work) {
      score += 15
    }

    if (undefined !== this.value.qualified_profession && this.value.qualified_profession !== 0) {
      score += 30
    }

    if (this.value.language) {
      score += this.value.language
    }

    if (this.value.hk_family) {
      score += 5
    }

    if (this.value.spouse_bachelor) {
      score += 5
    }

    if (this.value.children) {
      score += this.value.children
    }

    return score
  }
}
