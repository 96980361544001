import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { CommonShopService } from '../../common/common-shop/common-shop.service';
import { AppHttpService, http_post_type } from 'src/app/app-http.service';
import { ClientLoginService } from '../client-login/client-login.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-client-application',
  templateUrl: './client-application.component.html',
  styleUrls: ['./client-application.component.scss']
})

export class ClientApplicationComponent implements OnInit {
  constructor(
    private router: Router,
    private activated_route: ActivatedRoute,
    private http: AppHttpService,
    private common_shop_service: CommonShopService,
    private login: ClientLoginService,
    private meta: Meta,
  ) { }

  ngOnInit() {
    let self = this
    this.login.init({
      on_succeed_callback: function () {
        self.on_init_succeed()
      },
      ignore_subscribe: true
    })

    this.meta.updateTag({ property: 'og:title', content: '吉叔工作室服务费缴费' });
    this.meta.updateTag({ property: 'og:description', content: '吉叔工作室服务费缴费' });
  }

  on_init_succeed() {

  }

  get configs() {
    return {
      id: 0,
      configs: this.common_shop_service.application_configs
    }
  }

  get open_id() {
    return this.login.open_id
  }

  on_pay_succeed(e) {

  }

  get desc()
  {
    return "吉叔工作室服务费缴费";
  }
}
