import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientHomeComponent } from "./client/client-home/client-home.component"
import { ClientEvaluateComponent } from "./client/client-evaluate/client-evaluate.component"
import { ClientEvaluateResultComponent } from "./client/client-evaluate-result/client-evaluate-result.component"
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';
import { AdminClientTdComponent } from './admin/admin-client-td/admin-client-td.component';
import { AdminEvaluateComponent } from './admin/admin-evaluate/admin-evaluate.component';
import { AdminEvaluateReportComponent } from './admin/admin-evaluate-report/admin-evaluate-report.component';
import { ClientEvaluateReportComponent } from './client/client-evaluate-report/client-evaluate-report.component';
import { ClientEvaluateResultsComponent } from './client/client-evaluate-results/client-evaluate-results.component';
import { ClientBillingComponent } from './client/client-billing/client-billing.component';
import { ClientBillingsComponent } from './client/client-billings/client-billings.component';
import { ClientApplicationComponent } from './client/client-application/client-application.component';
import { ClientWxQrcodeComponent } from './client/client-wx-qrcode/client-wx-qrcode.component';
import { ClientCouponComponent } from './client/client-coupon/client-coupon.component';
import { ClientMacauComponent } from './client/client-macau/client-macau.component';
import { ClientCourseComponent } from './client/client-course/client-course.component';
import { ClientTestComponent } from './client/client-test/client-test.component';
import { ClientPrepayComponent } from './client/client-prepay/client-prepay.component';
import { ClientInterviewComponent } from './client/client-interview/client-interview.component';
import { ClientRegularCustomerComponent } from './client/client-regular-customer/client-regular-customer.component';
import { Client90offComponent } from './client/client-90off/client-90off.component';
import { ClientBigSaleComponent } from './client/client-big-sale/client-big-sale.component';
import { ClientGroupSaleComponent } from './client/client-group-sale/client-group-sale.component';
import { ClientPaypayPayTestComponent } from './client/client-pay-pay-pay-test/client-pay-pay-pay-test.component'
import { ClientPayResultComponent } from './client/client-pay-result/client-pay-result.component';
import { ClientHKComponent } from './client/client-hk/client-hk.component'; 

const routes: Routes = [
  { path: 'client/evaluate', component: ClientEvaluateComponent },
  { path: 'client/evaluate_result/:id', component: ClientEvaluateResultComponent },
  { path: 'client/evaluate_report/:id', component: ClientEvaluateReportComponent },
  { path: 'client/pay_result/:id', component: ClientPayResultComponent },
  { path: 'client/evaluate_results', component: ClientEvaluateResultsComponent },
  { path: 'client/qrcode', component: ClientWxQrcodeComponent },
  { path: 'client/application', component: ClientApplicationComponent },
  { path: 'client/billing/:id', component: ClientBillingComponent },
  { path: 'client/billings', component: ClientBillingsComponent },
  { path: 'client/coupon', component: ClientCouponComponent },
  { path: 'client/macau', component: ClientMacauComponent },
  { path: 'client/interview', component: ClientInterviewComponent },
  { path: 'client/course', component: ClientCourseComponent },
  { path: 'client/test', component: ClientTestComponent },
  { path: 'client/prepay', component: ClientPrepayComponent },
  { path: 'client/regular_customer', component: ClientRegularCustomerComponent },
  { path: 'client/group_sale', component: ClientGroupSaleComponent },
  { path: 'client/pay_pay_pay_test', component: ClientPaypayPayTestComponent },
  { path: 'client/90off', component: Client90offComponent },
  { path: 'client/big_sale', component: ClientBigSaleComponent },
  { path: 'client/pay_result', component: ClientPayResultComponent },
  { path: 'client/hk', component: ClientHKComponent },
  { path: 'admin/home', component: AdminHomeComponent },
  { path: 'admin/admin-client-td', component: AdminClientTdComponent },
  { path: 'admin/evaluate/:id', component: AdminEvaluateComponent },
  { path: 'admin/evaluate_report/:id', component: AdminEvaluateReportComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
