import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-common-select',
  templateUrl: './common-select.component.html',
  styleUrls: ['./common-select.component.scss']
})

export class CommonSelectComponent implements OnInit {
  @Input() value: any = {}

  constructor() { }

  ngOnInit() {
  }
}
